import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import ROUTES from '../../../routes';
import {
  BoxMenu,
  BoxSeparator,
  ContentBox,
  EmptyMessage,
  ForbiddenSection,
  OperationsTable,
  Page,
  TitleBox,
} from '../../../components';
import { business as businessActions } from '../../../actions';
import { Language } from '../../../utils';

import ListItemText from '@material-ui/core/ListItemText';
import { Add as AddIcon, Edit as EditIcon, GetApp as DownloadIcon } from '@material-ui/icons';
import MoreVerticalIcon from '../../../static/images/icons/more_vertical.svg';

import makeClasses from './styles';
import { IconButton, ListItemIcon, Menu, MenuItem, useMediaQuery, useTheme } from '@material-ui/core';
import { Policies } from '../../../utils/Policies';
import { toast } from 'react-toastify';

const ProvidersSubscriptionsManageTermsAndConditionsScreen = () => {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuOpenIndex, setMenuOpenIndex] = useState(-1);
  const [forbiddenSection, setForbbidenSection] = useState(false);
  const [branches, setBranches] = useState([]);

  const { policies, languageTexts } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);
  const hasBranches = branches?.length > 0;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!policies || !policies.includes(Policies.types.PROVIDER)) {
      setForbbidenSection(true);
    } else {
      dispatch(businessActions.getProvidersSubscriptionsMerchant((data) => {
        setBranches(data.branches?.filter(b => b.serviceStatus === 'ACTIVE')?.map(b => ({ traceId: b.branchId, name: b.name })) || []);
      }))
    }
  }, [policies]);

  const toggleMenu = (index) => (e) => {
    if (menuAnchorEl) {
      setMenuAnchorEl(null);
      setMenuOpenIndex(-1);
    } else {
      setMenuAnchorEl(e.target);
      setMenuOpenIndex(index);
    }
  };

  const handleOnEditPlan = (branch) => () => {
    toggleMenu()();
    dispatch(
      push(ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_TERMS_AND_CONDITIONS_EDIT.path.replace(':traceId', branch.traceId))
    );
  };

  const handleOnDownloadPlan = (branch) => () => {
    toggleMenu()();
    dispatch(
      businessActions.getProvidersSubscriptionsTermsAndConditionsLink(branch.traceId, (link) => {
        if (link) {
          window.open(link, '_blank');
        } else {
          toast.error(i18n.get('ProvidersSubscriptionsManageTermsAndConditions.ErrorNoLink'));
        }
      })
    );
  };

  const renderTermsAndConditions = () => {
    if (!branches || branches.length === 0) {
      return (
        <EmptyMessage textOnly>
          {i18n.get('ProvidersSubscriptionsManageTermsAndConditions.GridEmptyMessage')}
        </EmptyMessage>
      );
    }

    const headers = [
      {
        canHide: false,
        columnNumber: 1,
        name: i18n.get('ProvidersSubscriptionsManageTermsAndConditions.TermsGrid.Column1'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 2,
        name: i18n.get('ProvidersSubscriptionsManageTermsAndConditions.TermsGrid.Column2'),
        sortable: false,
        type: 'custom',
        show: !isSm,
        align: 'right',
      },
    ];
    const body = (branches || []).map((item, index) => ({
      rowId: item.traceId,
      columns: [
        {
          columnNumber: 1,
          data: item.name,
        },
        {
          columnNumber: 2,
          data: () => {
            return (
              <div className={classes.listItemActions}>
                <IconButton onClick={toggleMenu(index)}>
                  <img src={MoreVerticalIcon} alt="More" />
                </IconButton>
                <Menu
                  anchorEl={menuAnchorEl}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  keepMounted
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  onClose={toggleMenu()}
                  open={menuOpenIndex === index}
                  className={classes.listItemMenu}
                >
                  <MenuItem onClick={handleOnEditPlan(item)} className={classes.listItemMenuItem}>
                    <ListItemIcon className={classes.menuItemIcon}>
                      <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={i18n.get('ProvidersSubscriptionsManageTermsAndConditions.ActionEdit')}
                      className={classes.menuItemText}
                    />
                  </MenuItem>
                  <MenuItem onClick={handleOnDownloadPlan(item)} className={classes.listItemMenuItem}>
                    <ListItemIcon className={classes.menuItemIcon}>
                      <DownloadIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={i18n.get('ProvidersSubscriptionsManageTermsAndConditions.ActionDownload')}
                      className={classes.menuItemText}
                    />
                  </MenuItem>
                </Menu>
              </div>
            );
          },
        },
      ],
    }));
    
    return (
      <OperationsTable
        headers={headers}
        body={body}
        emptyMessage={i18n.get('ProvidersSubscriptionsManageTermsAndConditionsView.GridEmptyMessage')}
        showDetails={false}
      />
    );
  };

  const handleOnMenuItemClick = (item) => {
    dispatch(push(item.path));
  };

  const handleOnClickAddNewTermsAndConditions = () => {
    dispatch(push(ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_TERMS_AND_CONDITIONS_ADD.path));
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get('ProvidersSubscriptionsManageTermsAndConditions.Title')}
        withActivePage={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.id}
        withBanner={false}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get('ProvidersSubscriptionsManageTermsAndConditions.Title')}
      withActivePage={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.id}
    >
      <BoxMenu
        items={[
          {
            ...ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_PLANS,
            label: i18n.get('ProvidersSubscriptionsManageTermsAndConditions.BoxMenu.Item1'),
          },
          {
            ...ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_PROMOTIONS,
            label: i18n.get('ProvidersSubscriptionsManageTermsAndConditions.BoxMenu.Item3'),
          },
          {
            ...ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTIONS,
            label: i18n.get('ProvidersSubscriptionsManageTermsAndConditions.BoxMenu.Item2'),
          },
          {
            ...ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_TRANSACTIONS,
            label: i18n.get('ProvidersSubscriptionsManageTermsAndConditions.BoxMenu.Item4'),
          },
          {
            ...ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_TERMS_AND_CONDITIONS,
            label: i18n.get('ProvidersSubscriptionsManageTermsAndConditions.BoxMenu.Item5'),
          },
        ]}
        selectedItem={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_TERMS_AND_CONDITIONS.id}
        onClickItem={handleOnMenuItemClick}
      />
      <TitleBox
        title={i18n.get('ProvidersSubscriptionsManageTermsAndConditions.BoxTitle')}
        buttonRight
        buttonRightIcon={() => <AddIcon fontSize="small" />}
        buttonRightText={isSm ? null : i18n.get('ProvidersSubscriptionsManageTermsAndConditions.BoxTitleButtonText')}
        buttonRightOnClick={handleOnClickAddNewTermsAndConditions}
      />
      <BoxSeparator size="small" />
      <ContentBox title={i18n.get('ProvidersSubscriptionsManageTermsAndConditions.SubscribersGridTitle')} titleBold>
        {hasBranches ? (
          renderTermsAndConditions()
        ) : (
          <div className={classes.noDataMessage}>
            {i18n.get('ProvidersSubscriptionsManageTermsAndConditions.NoTermsMessage')}
          </div>
        )}
      </ContentBox>
    </Page>
  );
};

ProvidersSubscriptionsManageTermsAndConditionsScreen.id = 'com.Handy.ProvidersSubscriptionsManageTermsAndConditions';

export default ProvidersSubscriptionsManageTermsAndConditionsScreen;
